@import './common.module.scss';

.footer{
    padding-top: 10rem;
    padding-bottom: 2rem;
    float: left;
    background-color: black;
    width: 100%;
    color: #D7D7D7;

    .container{
        padding: 1.7rem 0rem;
        border-top: 0.1rem solid #D7D7D7;
        border-bottom: 0.1rem solid #D7D7D7;

        p{
            text-align: center;

            &:first-child{
                font-size: 2.2rem;
                font-weight: 500;
            }

            &:last-child{
                font-size: 1.8rem;
                font-weight: 500;
            }
        }
    }
}