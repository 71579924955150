@import 'variable.module.scss';

.home{
    background-color: #0D0D10;
    width: 100%;
    height: 100%;
    float: left;

    .description{
        width: 100%;
        height: 100dvh;
        display: flex;
        position: relative;
        background-image: url('../images/descriptionBg.png');
        background-position: 100% 100%;

        @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            height: 100%;

        }

        .description_details{
            margin-top: 20rem;
            position: absolute;
            z-index: 1;
            width: $container-width;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            
            @media (max-width: 992px) {
                position: relative;
            }
            

            h2{
                font-size: 10rem;
                font-weight: 400;

                @media (max-width: 1700px) {
                    font-size: 8rem;
                }

                @media (max-width: 1500px) {
                    font-size: 6rem;
                }

                @media (max-width: 768px) {
                    font-size: 5rem;
                }

                @media (max-width: 600px) {
                    font-size: 4rem;
                }

                @media (max-width: 500px) {
                    font-size: 3rem;
                }

                span{
                    &.bold{
                        font-weight: 700;
                    }
                }
            }

            p{
                font-size: 2.5rem;
                font-weight: 400;
                max-width: 50%;
                color: #D7D7D7;
                text-align: justify;
                margin-top: 6rem;

                @media (max-width: 1700px) {
                    font-size: 2.2rem;
                    margin-top: 5rem;
                }

                @media (max-width: 1500px) {
                    font-size: 2rem;
                    margin-top: 4rem;
                }

                @media (max-width: 992px) {
                    max-width: 100%;
                }

                @media (max-width: 768px) {
                    font-size: 1.8rem;
                }

                @media (max-width: 600px) {
                    margin-top: 2rem;
                    font-size: 1.6rem;
                }
            }
        }
        

        .description_image{
            position: absolute;
            top: 1rem;
            right: 0%;
            height: 100%;
            overflow: hidden;

            @media (max-width: 992px) {
                position: relative;
            }
            
            @media (max-width: 768px) {
                top: 5rem;
                display: flex;
                align-items: center;
                
            }

            img{
                height: 100%;
                width: 100%;

                @media (max-width: 768px) {
                    height: auto;
                }
            }
        }

        .description_image_layer{
            position: absolute;
            height: 70rem;
            mix-blend-mode: soft-light;
            right: 10%;
            top: 1rem;
            // background-color: red;

            img{
                height: 100%;
            }
        }

    }

    .artist{
        width: 100%;
        float: left;
        background-color: black;
        padding: 20rem 0rem;

        @media (max-width: 1500px) {
            padding: 15rem 0rem;
        }

        @media (max-width: 992px) {
            padding: 20rem 0rem 0rem 0rem;
        }

        .container{
            width: $container-width;
            margin: $container-margin;
            display: flex;
            align-items: center;

            @media (max-width: 992px) {
                flex-direction: column;
            }

            .artist_details{
                width: 47%;

                @media (max-width: 992px) {
                    width: 100%;
                }

                h2{
                    font-size: 5.5rem;
                    font-weight: 700;
                    color: white;

                    @media (max-width: 1700px) {
                        font-size: 4.5rem;
                    }
    
                    @media (max-width: 1500px) {
                        font-size: 3.8rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 3.3rem;
                    }

                    @media (max-width: 768px) {
                        font-weight: 600;
                    }

                    @media (max-width: 500px) {
                        font-weight: 500;
                        font-size: 2.8rem;
                    }
                }

                p{
                    font-size: 2.5rem;
                    text-align: justify;
                    color: #D7D7D7;
                    margin-top: 2rem;

                    @media (max-width: 1700px) {
                        font-size: 2.2rem;
                    }
    
                    @media (max-width: 1500px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 1.6rem;
                    }
                }

                a{
                    font-size: 2.5rem;
                    font-weight: 500;
                    border: none;
                    border-radius: 0.5rem;
                    color: white;
                    padding: 1.5rem 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 0.1rem solid #868686;
                    text-decoration: none;
                    margin-top: 3rem;
                    cursor: pointer;
                    width: fit-content;

                    @media (max-width: 1700px) {
                        font-size: 2.2rem;
                    }
    
                    @media (max-width: 1500px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 500px) {
                        font-size: 1.6rem;
                    }

                    &:hover{
                        img{
                            transform: translateX(0.5rem);
                        }
                    }
    
                    img{
                        transition: transform 0.5s;
                        margin-left: 1rem;
                    }
                }
            }

            .artist_image{
                width: 53%;
                position: relative;
                height: 50rem;

                @media (max-width: 992px) {
                    width: 100%;
                    height: 60rem;

                }

                .artist_laptop{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                    right: 0%;
                    top: 0%;
                    z-index: 11;

                    @media (max-width: 992px) {
                        justify-content: center;
                        margin-top: 5rem;
                    }

                    @media (max-width: 800px) {
                        margin-top: 0rem;
                    }

                    img{
                        width: 80%;
                        @media (max-width: 700px) {
                            width: 100%;
                        }
                    }
                }

                .artist_laptop_bg{
                    width: 90%;
                    height: 100%;
                    position: absolute;
                    right: 0%;
                    top: 0%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    z-index: 10;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }


    .mobile{
        width: 100%;
        float: left;
        background-color: black;
        padding: 20rem 0rem;

        @media (max-width: 992px) {
            padding: 0rem 0rem 0rem 0rem;
        }

        .container{
            width: $container-width;
            margin: $container-margin;
            display: flex;
            align-items: center;

            @media (max-width: 992px) {
                flex-direction: column;
            }

            .mobile_details{
                width: 60%;
                height: 100%;

                @media (max-width: 992px) {
                    width: 100%;
                }

                h2{
                    font-size: 5.5rem;
                    font-weight: 700;
                    color: white;

                    @media (max-width: 1700px) {
                        font-size: 4.5rem;
                    }
    
                    @media (max-width: 1500px) {
                        font-size: 3.8rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 3.3rem;
                    }

                    @media (max-width: 768px) {
                        font-weight: 600;
                    }

                    @media (max-width: 500px) {
                        font-weight: 500;
                        font-size: 2.8rem;
                    }
                }

                p.mobile_description{
                    font-size: 2.5rem;
                    text-align: justify;
                    color: #D7D7D7;
                    margin-top: 2rem;

                    @media (max-width: 1700px) {
                        font-size: 2.2rem;
                    }
    
                    @media (max-width: 1500px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 1.6rem;
                    }
                }

                .buttons{
                    margin-top: 5rem;
                    display: flex;
                    gap: 3rem;

                    @media (max-width: 500px) {
                        flex-direction: column;
                    }

                    a{
                        border: none;
                        height: 8.5rem;
                        width: 27.5rem;
                        border-radius: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0.1rem solid #868686;
                        text-decoration: none;
                        cursor: pointer;

                        @media (max-width: 1700px) {
                            height: 8rem;
                            width: 25rem;
                        }
        
                        @media (max-width: 1500px) {
                            height: 7.5rem;
                            width: 23rem;
                        }
    
                        @media (max-width: 1200px) {
                            height: 7rem;
                            width: 20rem;
                        }

                        @media (max-width: 768px) {
                            width: 50%;
                        }

                        @media (max-width: 500px) {
                            width: 100%;
                        }

                        img{
                            margin-right: 1.5rem;
                            height: 60%;

                            @media (max-width: 1500px) {
                                height: 55%;
                            }
        
                            @media (max-width: 1200px) {
                                height: 50%;
                            }
                        }

                        p{
                            span{
                                &:first-child{
                                    font-size: 1.5rem;
                                    font-weight: 400;
                                    color: #E3E0E0;
                    
                                    @media (max-width: 1500px) {
                                        font-size: 1.4rem;
                                    }
                
                                    @media (max-width: 1200px) {
                                        font-size: 1.3rem;
                                    }
                                }
                                &:last-child{
                                    font-size: 2.5rem;
                                    font-weight: 500;
                                    color: #E3E0E0;

                                    @media (max-width: 1700px) {
                                        font-size: 2.2rem;
                                    }
                    
                                    @media (max-width: 1500px) {
                                        font-size: 1.8rem;
                                    }
                
                                    @media (max-width: 1200px) {
                                        font-size: 1.6rem;
                                    }
                                }
                            }
                        }
    
                        &:hover{
                            img{
                                // transform: translateX(0.5rem);
                            }
                        }
                    }
                }

                
            }

            .mobile_image{
                width: 40%;
                position: relative;
                height: 50rem;

                @media (max-width: 992px) {
                    width: 100%;
                }

                @media (max-width: 768px) {
                    height: 45rem;
                }

                .mobile_laptop{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                    right: 0%;
                    top: 0%;
                    z-index: 11;
                    // background-color: skyblue;

                    @media (max-width: 992px) {
                        height: 100%;
                        width: auto;
                        justify-content: center;
                        right: 50%;
                        transform: translateX(50%);
                    }
                    img{
                        width: 80%;

                        @media (max-width: 768px) {
                            width: 100%;
                        }

                        @media (max-width: 600px) {
                            margin-top: 15rem;
                            width: 110%;
                        }

                        @media (max-width: 500px) {
                            width: 150%;
                        }
                    }
                }

                .mobile_laptop_bg{
                    width: 110%;
                    height: 110%;
                    position: absolute;
                    right: 0%;
                    top: 0%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    z-index: 10;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }


    .info{
        width: 100%;
        float: left;
        background-color: black;

        .container{
            width: $container-width;
            margin: $container-margin;
            display: flex;
            align-items: center;

            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }

            .info_details{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 5rem;

                @media (max-width: 1400px) {
                    gap: 3rem;
                    width: 60%;
                }

                @media (max-width: 1200px) {
                    gap: 2rem;
                }

                @media (max-width: 992px) {
                    width: 100%;
                }

                h3{
                    font-size: 2.5rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    color: white;

                    img{
                        width: 2.5rem;
                    }

                    @media (max-width: 1400px) {
                        font-size: 2.2rem;
                    }

                    @media (max-width: 1200px) {
                        font-size: 2rem;
                    }
                }

                p{
                    font-size: 1.8rem;
                    color: #D7D7D7;

                    @media (max-width: 1200px) {
                        font-size: 1.6rem;
                    }
                }

                a{
                    font-size: 2.5rem;
                    font-weight: 500;
                    border: none;
                    border-radius: 0.5rem;
                    color: white;
                    padding: 1.5rem 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border: 0.1rem solid #868686;
                    text-decoration: none;
                    margin-top: 3rem;
                    cursor: pointer;
                    width: fit-content;

                    &:hover{
                        img{
                            transform: translateX(0.5rem);
                        }
                    }
    
                    img{
                        transition: transform 0.5s;
                        margin-left: 1rem;
                    }
                }

                .info_one,
                .info_two,
                .info_three{
                    background-image: linear-gradient(to bottom, #0D0D10, #23252C);
                    border-radius: 0.5rem;
                    padding: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.8rem;

                    @media (max-width: 1200px) {
                        padding: 2rem;
                        gap: 1rem;
                    }

                    
                }
            }

            .info_image{
                width: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                


                @media (max-width: 1400px) {
                    width: 40%;
                }

                @media (max-width: 992px) {
                    width: 100%;
                    overflow: hidden;
                }

                img{
                    width: 100%;

                    @media (max-width: 1200px) {
                        width: 110%;
                        margin-left: -5rem;
                    }

                    @media (max-width: 992px) {
                        margin-left: -3rem;
                    }
                }
            }
        }
    }
}