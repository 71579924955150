@import './common.module.scss';

.nav{
    position: absolute;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.274);
    height: 10rem;
    display: flex;
    align-items: center;
    z-index: 100;

    .logo{
        height: 10rem;
        display: flex;
        align-items: center;

        img{
            height: 50%;
        }
    }
}