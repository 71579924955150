@import './common.module.scss';

// $heading-font-size: 5.8rem;
// $heading-font-weight: 600;
// $font-color: white;

// $sub-heading-font-size: 3.5rem;
// $sub-heading-font-weight: 600;

// $description-heading-font-size: 2.5rem;
// $description-heading-font-weight: 400;


$heading-font-size: 5rem;
$heading-font-weight: 600;
$font-color: white;

$sub-heading-font-size: 3rem;
$sub-heading-font-weight: 600;

$description-heading-font-size: 2rem;
$description-heading-font-weight: 400;

.privacyPolicy{
    width: 100%;
    float: left;

    .topSection{
        height: 50rem;
        background-image: url('../images/privacyBg.png');
        display: flex;
        align-items: center;

        h2{
            color: white;
            text-align: center;
            font-size: 8rem;
            font-weight: 600;
        }
    }

    .contentSection{
        width: 100%;
        float: left;
        background-color: black;

        .details{
            h3{
                font-size: $heading-font-size;
                font-weight: $heading-font-weight;
                margin-top: 5rem;
                color: $font-color;
            }

            h4{
                font-size: $sub-heading-font-size;
                font-weight: $sub-heading-font-weight;
                margin-top: 2rem;
                color: $font-color;
            }
            
            .content{
                padding-left: 2rem;
                color: #D7D7D7;
                
                h5{
                    font-size: $sub-heading-font-size;
                    font-weight: $sub-heading-font-weight;
                    margin-top: 3rem;
                    color: $font-color;
                }
                
                p{
                    margin-top: 1.5rem;
                    font-size: $description-heading-font-size;
                    font-weight: $description-heading-font-weight;

                    &.extra{
                        display: flex;
                        gap: 1.5rem;
                        padding-left: 1.5rem;

                        img{
                            margin-top: 0.3rem;
                            // width: 2.2rem;
                            // height: 2.6rem;
                            width: 1.8rem;
                            height: 2rem;
                        }
                    }

                    &:first-child{
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}

